import React, { useEffect } from "react";
import * as styles from "./index.module.scss";
import { PageLayout, TypographyV2 } from "@components";
import Input from "@components/InputV2/InputV2";
import Select from "@components/SelectV2/SelectV2";
import { useHubspotForm, useWindowSize } from "@helpers/Hooks";
import { SubmitbuttonV2 } from "@components/Button_new/Button";
import GenemodLink from "@components/Link/Link";
import { StaticImage } from "gatsby-plugin-image";
import Margins from "@components/Margins/Margins";
import cn from "classnames";
import freeEmailDomains from "free-email-domains";
import { useLocation } from "@reach/router";
import TestimonialCard from "@components/TestimonialCard/TestimonialCard";

const DEMO_SUBMISSION_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/f0436806-af62-4b53-8bfd-f3f4de1bf949`;

const PLANS = [
	"Industry - Team",
	"Industry - Business",
	"Industry - Enterprise",
	"Academia",
	"Institution",
];

const FIND_US = [
	"Google / Search engine",
	"Word of mouth",
	"Email / Newsletter",
	"LinkedIn",
	"Online article",
	"Other",
] as const;
const TEAM_SIZE = ["1 - 7", "8 - 15", "16 - 50", "51 - 100", "100 +"] as const;

export default function Demo({ location }: any) {
	const { Option } = Select;
	const { isMobile, isTablet } = useWindowSize();
	const isDesktop = !isMobile && !isTablet;
	const newLocation = useLocation();
	const state = newLocation.state || {}; // Extract initial plan from location state
	const { inputProps, handleSubmit, submitStatus, initForm } = useHubspotForm(
		{
			interested_plan: state.selectedPlan || "",
			email: location?.state?.email || "",
			firstname: "",
			lastname: "",
			phone: "",
			company: "",
			team_size_range: "",
			how_did_you_find_us_: "",
		},
		DEMO_SUBMISSION_ROUTE
	);

	const validateFreeEmail = (value: string): boolean => {
		const emailDomain = value.split("@");
		if (emailDomain[1] && freeEmailDomains.includes(emailDomain[1])) {
			return false;
		}
		return true;
	};

	return (
		<PageLayout
			footerTheme="dark"
			seoOptions={{ type: "PREDEFINED", pageName: "demo" }}
		>
			<Margins className={styles.margins}>
				<div className={styles.container}>
					{/* new layout */}
					<div className={styles.containerGrid}>
						{/* left */}
						<div className={styles.leftContainer}>
							<div className={styles.textContainer}>
								<TypographyV2
									variant="HEADING_1"
									weight="MEDIUM"
									color="brand-500"
									className={styles.title}
								>
									Let's Talk
								</TypographyV2>
								<TypographyV2
									variant="BODY_TEXT_EXTRA_LARGE"
									weight="REGULAR"
									color="text-helper"
									className={cn(styles.connectText)}
								>
									Connect with an expert to see if Genemod is
									right for you.
								</TypographyV2>
								<hr className={styles.horizontalRule} />
								<TypographyV2
									variant="HEADING_5"
									mobileVariant="BODY_TEXT_EXTRA_LARGE"
									weight={isMobile ? "SEMIBOLD":"MEDIUM"}
									color="text-helper"
									className={styles.subtitle}
								>
									You're in great company
								</TypographyV2>
								<TypographyV2
									variant="BODY_TEXT_EXTRA_LARGE"
									weight="REGULAR"
									color="text-helper"
									className={cn(styles.subtitleSecondary)}
								>
									Join the hundreds of trusted institutions
									that choose Genemod to increase productivity{" "}
								</TypographyV2>
							</div>
							<div className={styles.logos}>
								<StaticImage
									src="../../assets/demo/image 17.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/pfizer.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/eurofins.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/stanford.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/image 4.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/bayer.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
							</div>
							<div className={styles.testimonialCard}>
								<TestimonialCard />
							</div>
						</div>
						{/* Right container */}
						<div className={styles.rightContainer}>
							<div className={styles.body}>
								<Input
									className={styles.input}
									inputClassName={cn(styles.input, styles.customPadding)}
									label={"Work email"}
									{...inputProps("email")}
									autoComplete="email"
									fullWidth
									emailValidator
									placeholder="email@company.com"
									inputErrorColor="error-400"
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
									validators={[
										{
											validator: (val) =>
												validateFreeEmail(val),
											error: "Please enter a business email",
										},
									]}
								/>

								<div className={styles.inputGrid}>
									<Input
										label={"First name"}
										className={styles.input}
										inputClassName={cn(styles.input, styles.customPadding)}
										{...inputProps("firstname")}
										autoComplete="given-name"
										fullWidth
										required
										inputErrorColor="error-400"
										customErrorMessage="Required"
										disabled={
											submitStatus === "SUCCESS" ||
											submitStatus === "SUBMITTING"
										}
									/>
									<Input
										label={"Last name"}
										className={styles.input}
										inputClassName={cn(styles.input, styles.customPadding)}
										{...inputProps("lastname")}
										autoComplete="family-name"
										fullWidth
										required
										inputErrorColor="error-400"
										customErrorMessage="Required"
										disabled={
											submitStatus === "SUCCESS" ||
											submitStatus === "SUBMITTING"
										}
									/>
								</div>

								<Input
									label={"Phone number (optional)"}
									className={styles.input}
									inputClassName={cn(styles.input, styles.customPadding)}
									{...inputProps("phone")}
									fullWidth
									placeholder="000-000-0000"
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
								/>
								<div className={styles.inputGrid}>
									<Input
										label={"Organization"}
										className={styles.input}
										inputClassName={cn(styles.input, styles.customPadding)}
										{...inputProps("company")}
										fullWidth
										required
										inputErrorColor="error-400"
										customErrorMessage="Required"
										disabled={
											submitStatus === "SUCCESS" ||
											submitStatus === "SUBMITTING"
										}
									/>
									{/* <TeamSizeSelect
										label={"Select team size"}
										{...inputProps("team_size_range")}
										fullWidth
										required
									/> */}
									<Select
										label={"Team size"}
										{...inputProps("team_size_range")}
										fullWidth
										required
										inputErrorColor="error-400"
										customErrorMessage="Required"
										disabled={
											submitStatus === "SUCCESS" ||
											submitStatus === "SUBMITTING"
										}
									>
										{TEAM_SIZE.map((opt) => (
											<Option key={opt}>{opt}</Option>
										))}
									</Select>
								</div>
								<Select
									label={"Which plan are you interested in?"}
									{...inputProps("interested_plan")}
									fullWidth
									required
									defaultValue={state.selectedPlan || ""} // Set default value for select input
									inputErrorColor="error-400"
									customErrorMessage="Required"
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
								>
									{PLANS.map((opt) => (
										<Option key={opt}>{opt}</Option>
									))}
								</Select>
								<Select
									label={"How did you find us?"}
									{...inputProps("how_did_you_find_us_")}
									fullWidth
									required
									inputErrorColor="error-400"
									customErrorMessage="Required"
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
								>
									{FIND_US.map((opt) => (
										<Option key={opt}>{opt}</Option>
									))}
								</Select>
								<div className={styles.submitSection}>
									<SubmitbuttonV2
										submitStatus={submitStatus}
										// submitStatus={"SUCCESS"}
										onClick={handleSubmit}
										className={styles.submitButton}
										containerClassName={
											styles.submitButtonContainer
										}
									>
										Submit
									</SubmitbuttonV2>
									{submitStatus === "NOT_SUBMITTED" && (
										<TypographyV2
											variant="BODY_TEXT_EXTRA_SMALL"
											weight="REGULAR"
											color="text-helper"
										>
											By requesting a demo you agree to
											Genemod’s{" "}
											<GenemodLink
												link="PRIVACY_POLICY"
												className={styles.privacyLink}
											>
												Privacy Policy
											</GenemodLink>
											, and you consent to receive
											marketing communications from
											Genemod.
										</TypographyV2>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Margins>
		</PageLayout>
	);
}
