import React, { useState } from "react";
import * as styles from "./TestimonialCard.module.scss";
import cn from "classnames";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import index from "pages/ads";

interface Testimonial {
	name: string;
	institution: string;
	quote: string;
}

const testimonials: Testimonial[] = [
	{
		name: "Jiah L.",
		institution: "Small-Business (50 or fewer emp.)",
		quote: `“UI of Genemod platform is really easy to use and integrating our previous research data into Genemod system is very convenient.”`,
	},
	{
		name: "Anton N.",
		institution: "Small-Business (50 or fewer emp.)",
		quote: `“We have been using Genemod for a short period of time, but are already finding the interface and ease of use to be much better than our current inventory software.”`,
	},
	{
		name: "Keith K.",
		institution: "Enterprise (>1000 emp.)",
		quote: `“Genemod offers a great LIMS software for research laboratories. Our research team is using Genemod almost everyday for our inventory management.”`,
	},
	{
		name: "Angie R.",
		institution: "Small-Business (50 or fewer emp.)",
		quote: `“I use Genemod daily, it is highly accessible and intuitive. The inventory tools allow for easy organization and integration with ordering and reports is valuable.”`,
	},
];

export default function TestimonialCard() {
	const [currentIndex, _setCurrentIndex] = useState(0);
	const [startPos, setStartPos] = useState({ x: 0, y: 0 });
	const [direction, setDirection] = useState<"next" | "prev">("next");

	const setCurrentIndex = (index: number) => {
		let i = index;
		if (index >= testimonials.length) {
			i = 0;
		} else if (index < 0) {
			i = testimonials.length - 1;
		}
		_setCurrentIndex(i);
	};

	const handlePrevClick = () => {
		setDirection("prev");
		setCurrentIndex(currentIndex - 1);
	};

	const handleNextClick = () => {
		setDirection("next");
		setCurrentIndex(currentIndex + 1);
	};

	return (
		<div className={styles.carouselWrapper}>
			<button
				onClick={handlePrevClick}
				className={styles.navigationArrow}
			>
				<ArrowLeft />
			</button>
			<div className={styles.slideWrapper}>
				{testimonials.map((testimonial, index) => (
					<div
						key={`testimonial-${index}`}
						className={`${styles.slide} ${
							currentIndex === index
								? `${styles.activeSlide} ${
										styles[`slideIn${direction}`]
								  }`
								: `${styles.inactiveSlide} ${
										styles[`slideOut${direction}`]
								  }`
						}`}
					>
						<div className={styles.quoteWrapper}>
							<TypographyV2
								variant="BODY_TEXT_MEDIUM"
								color="base-black"
								className={styles.quote}
							>
								{testimonial.quote}
							</TypographyV2>
						</div>
						<div className={styles.testimonialWriter}>
							<TypographyV2
								weight="SEMIBOLD"
								variant="BODY_TEXT_MEDIUM"
								color="text-color-75"
								className={styles.writerName}
							>
								{testimonial.name}
							</TypographyV2>
							<TypographyV2
								variant="BODY_TEXT_SMALL"
								className={styles.authorInstitution}
								color="text-color-75"
							>
								{testimonial.institution}
							</TypographyV2>
						</div>
					</div>
				))}
			</div>
			<button
				onClick={handleNextClick}
				className={styles.navigationArrow}
			>
				<ArrowRight />
			</button>
		</div>
	);
}

const ArrowRight = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
		>
			<path
				d="M14 18.6667L18.6667 14M18.6667 14L14 9.33337M18.6667 14H9.33334M25.6667 14C25.6667 20.4434 20.4433 25.6667 14 25.6667C7.55669 25.6667 2.33334 20.4434 2.33334 14C2.33334 7.55672 7.55669 2.33337 14 2.33337C20.4433 2.33337 25.6667 7.55672 25.6667 14Z"
				stroke="#B9C0D4"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

const ArrowLeft = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
		>
			<path
				d="M14 9.33337L9.33333 14M9.33333 14L14 18.6667M9.33333 14H18.6667M25.6667 14C25.6667 20.4434 20.4433 25.6667 14 25.6667C7.55668 25.6667 2.33333 20.4434 2.33333 14C2.33333 7.55672 7.55668 2.33337 14 2.33337C20.4433 2.33337 25.6667 7.55672 25.6667 14Z"
				stroke="#B9C0D4"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
