// extracted by mini-css-extract-plugin
export var activeSlide = "TestimonialCard-module--activeSlide--8bTkJ";
export var authorInstitution = "TestimonialCard-module--authorInstitution--pmusQ";
export var carouselWrapper = "TestimonialCard-module--carouselWrapper--+mDdx";
export var inactiveSlide = "TestimonialCard-module--inactiveSlide--deVIM";
export var limitWidthOnLargeScreens = "TestimonialCard-module--limitWidthOnLargeScreens--UZxKD";
export var quote = "TestimonialCard-module--quote--SztWp";
export var quoteWrapper = "TestimonialCard-module--quoteWrapper--3TYyp";
export var slide = "TestimonialCard-module--slide--dM49B";
export var slideInNext = "TestimonialCard-module--slideInNext--Hd7vV";
export var slideInPrev = "TestimonialCard-module--slideInPrev--f-6oe";
export var slideInnext = "TestimonialCard-module--slideInnext--B5O1b";
export var slideInprev = "TestimonialCard-module--slideInprev--JCHFc";
export var slideOutNext = "TestimonialCard-module--slideOutNext--AbnMB";
export var slideOutPrev = "TestimonialCard-module--slideOutPrev--a6tFa";
export var slideOutnext = "TestimonialCard-module--slideOutnext--rKaBM";
export var slideOutprev = "TestimonialCard-module--slideOutprev--oNMzQ";
export var slideWrapper = "TestimonialCard-module--slideWrapper--3wXGP";
export var testimonialWriter = "TestimonialCard-module--testimonialWriter--Alq47";
export var writerName = "TestimonialCard-module--writerName--3LpWK";