// extracted by mini-css-extract-plugin
export var body = "index-module--body--RGuAD";
export var connectText = "index-module--connectText--EsPVA";
export var container = "index-module--container--mhDi7";
export var containerGrid = "index-module--containerGrid--EOqXu";
export var customPadding = "index-module--customPadding--r4S6b";
export var fullWidthImage = "index-module--fullWidthImage--GXw99";
export var horizontalRule = "index-module--horizontalRule--6rN06";
export var input = "index-module--input--qvClR";
export var inputGrid = "index-module--inputGrid--0hJip";
export var institutionCard = "index-module--institutionCard--f1izj";
export var leftContainer = "index-module--leftContainer--aXSFL";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--Li16v";
export var logoImage = "index-module--logoImage--24OiK";
export var logos = "index-module--logos--8NDBN";
export var margins = "index-module--margins--MVNeq";
export var message = "index-module--message--bky8W";
export var privacyLink = "index-module--privacyLink--iecCP";
export var rightContainer = "index-module--rightContainer--kuIi9";
export var submitButton = "index-module--submitButton--R97Ik";
export var submitButtonContainer = "index-module--submitButtonContainer--SEPA2";
export var submitSection = "index-module--submitSection--8EBN7";
export var subtitle = "index-module--subtitle--88PZs";
export var subtitleSecondary = "index-module--subtitleSecondary--WGf2b";
export var testimonialCard = "index-module--testimonialCard--Gm-oQ";
export var textContainer = "index-module--textContainer--wiSQE";
export var title = "index-module--title--aqRU6";